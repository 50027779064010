<template>
  <div class="form-complete-analysis">
    <h3 class="subtitle is-5">Quizzes</h3>

    <div class="notification is-primary">
      <div v-if="data.total_people !== null">
        Total people: {{ data.total_people }}
      </div>
      <div v-else>Loading..</div>
    </div>

    <div class="content">
      <div
        v-for="(quiz, q_key) in form.quizzes"
        :key="q_key"
        class="quiz-item notification">
        
        <nav class="level">
          <div class="level-left">
            <h5 class="title is-6 is-marginless">{{ quiz.question }}</h5>
          </div>

          <div class="level-right">
            <div v-if="data.quizzes[q_key].total_people !== null">
              Total people: <span class="has-text-link">{{ data.quizzes[q_key].total_people }}</span>
            </div>
            <div v-else class="has-text-grey-light">Loading..</div>
          </div>
        </nav>

        <div
          v-for="(option, o_key) in quiz.options"
          :key="o_key"
          class="options">
          
          <nav class="level">
            <div class="level-left">
              <p
                :class="{
                  'has-text-success': option.answer,
                  'has-text-danger': !option.answer
                }">

                {{ option.value }}
              </p>
            </div>

            <div class="level-right">
              <div v-if="data.quizzes[q_key].options[o_key].total_people !== null">
                Total people: <span class="has-text-link">{{ data.quizzes[q_key].options[o_key].total_people }}</span>
              </div>
              <div v-else class="has-text-grey-light">Loading..</div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCompleteAnalysis',
  props: {
    form: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data: function() {
    return {
      connection_error: false,
      loading: false,

      data: null,
    }
  },
  methods: {
    analyseForm: async function() {
      const response = await this.getAnalysis()

      if (response) {
        this.data.total_people = response.data.total
      }
    },
    analyseQuestion: async function(q_key, qId) {
      const response = await this.getAnalysis({
        quizzes: [
          {
            quiz: qId
          }
        ],
      })

      this.data.quizzes[q_key].total_people = response.data.total
    },
    analyseOption: async function(args) {
      const {
        q_key,
        o_key,
        question,
        option
      } = args

      const response = await this.getAnalysis({
        quizzes: [
          {
            quiz: question,
            choice: option
          }
        ],
      })

      this.data.quizzes[q_key].options[o_key].total_people = response.data.total
    },

    getAnalysis: async function(data={}) {
      const thisCompo = this

      const requestData = {
        form: this.form._id,
      }

      if (data.quizzes && data.quizzes.length) {
        requestData.quizzes = {
          operator: 'and',
          values: data.quizzes,
        }
      }

      const response = await this.$api.answerAnalysis(requestData).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
      })

      return response
    },
  },
  watch: {
    form: {
      immediate: true,
      handler: function(newValue) {
        if (!this.$isEmpty(newValue)) {
          const data = this.$deepCopy(newValue)

          data.total_people = null

          data.quizzes.map((quiz) => {
            quiz.total_people = null

            quiz.options.map((option) => {
              option.total_people = null
            })
          })

          this.data = data
        }
      }
    },
    data: {
      immediate: true,
      handler: function(newValue) {
        const thisCompo = this

        if (!this.$isEmpty(newValue)) {
          this.analyseForm()

          newValue.quizzes.map((quiz, q_key) => {
            thisCompo.analyseQuestion(q_key, quiz._id)

            quiz.options.map((option, o_key) => {
              thisCompo.analyseOption({
                q_key: q_key,
                o_key: o_key,
                question: quiz._id,
                option: option._id
              })
            })
          })
        }
      }
    },
  }
}
</script>

<style scoped>
</style>