<template>
  <div class="form-analysis">
    <div class="box">
      <div v-if="selectedData.quizzes.length" class="box">
        <b-field
          label="Operator"
          :type="{'is-danger': errors.has('operator')}"
          :message="errors.first('operator')">

          <b-select
            data-vv-as="Operator"
            name='operator'
            v-model="selectedData.quizzes_operator"
            placeholder="Select operator">

            <option
              v-for="(operator, key) in formValues.quizzes_operator"
              :value="operator.value"
              :key="key">

              {{ operator.name }}
            </option>
          </b-select>
        </b-field>

        <b-field>
          <b-table
            narrowed
            hoverable
            striped
            :data="selectedData.quizzes">

            <template slot-scope="props">

              <b-table-column field="quiz" label="Question" sortable>
                {{ questionName(props.row.quiz) }}
              </b-table-column>

              <b-table-column field="choice" label="Answer" sortable>
                <span v-if="props.row.choice" :class="choiceClass(props.row.quiz, props.row.choice)">{{ choiceName(props.row.quiz, props.row.choice) }}</span>
                <span v-else>Any</span>
              </b-table-column>

              <b-table-column field="actions" label="Actions">
                <b-button
                  @click.prevent="removeFilter(props.index)"
                  type="is-danger"
                  icon-left="delete"
                />
              </b-table-column>

            </template>
          </b-table>
        </b-field>
      </div>

      <b-field grouped>
        <b-field
          label="Question"
          :type="{'is-danger': errors.has('quiz')}"
          :message="errors.first('quiz')">

          <b-select
            :disabled="!unSelectedQuizzes.length ? true : false"
            data-vv-as="Question"
            name='quiz'
            v-model="filters.question"
            placeholder="Select quiz">

            <option
              v-for="(quiz, key) in unSelectedQuizzes"
              :value="quiz._id"
              :key="key">

              {{ quiz.question }}
            </option>
          </b-select>
        </b-field>

        <b-field
          v-if="filters.question"

          label="Answer"
          :type="{'is-danger': errors.has('answer')}"
          :message="errors.first('answer')">

          <b-select
            data-vv-as="Answer"
            name='answer'
            v-model="filters.answer"
            placeholder="Select answer">

            <option value="">- Any -</option>
            <option
              v-for="(option, key) in form.quizzes.find(q => q._id == filters.question).options"
              :value="option._id"
              :key="key">

              {{ option.value }} ({{ option.answer ? 'correct' : 'incorrect' }})
            </option>
          </b-select>
        </b-field>

        <div class="buttons">
          <b-button
            :disabled="!addFilterValid"
            @click.prevent="addFilter()"
            type="is-info"
            icon-left="plus">

            Add Filter
          </b-button>
        </div>
      </b-field>
    </div>

    <div v-if="!loading">
      <h2 class="subtitle">Result:</h2>

      <b-notification
        v-if="analysis"
        :closable="false"
        type="is-success"
        class="result">

        Total people answered: {{ analysis.total }}
      </b-notification>
    </div>
    <div v-else class="notification">
      <br>
      <br>
      <br>
      <b-loading :is-full-page="false" :active="true"></b-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormAnalysis',
  props: {
    form: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data: function() {
    return {
      connection_error: false,
      loading: false,

      filters: {
        question: null,
        answer: null,
      },
      selectedData: {
        quizzes: [],
        quizzes_operator: 'and',
      },

      formValues: {
        quizzes_operator: [
          {
            value: 'and',
            name: 'And',
          },
          {
            value: 'or',
            name: 'Or',
          },
        ],
      },

      analysis: null
    }
  },
  methods: {
    addFilter: function() {
      const filters = this.filters

      const data = {}

      this.selectedData.quizzes.push(data)

      if (filters.question) {
        data.quiz = filters.question
      }
      if (filters.answer) {
        data.choice = filters.answer
      }

      filters.question = null
      filters.answer = null
    },
    removeFilter: function(key) {
      this.selectedData.quizzes.splice(key, 1)
    },
    getAnalysis: async function(data={}) {
      const thisCompo = this

      const requestData = {
        form: this.form._id,
      }

      if (data.quizzes.length) {
        requestData.quizzes = {
          operator: data.quizzes_operator,
          values: data.quizzes,
        }
      }

      this.loading = true

      const response = await this.$api.answerAnalysis(requestData).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.connection_error = true
      })

      if (response) {
        this.analysis = response.data
      }

      this.loading = false
    },


    getQuizById: function(qid) {
      const quiz = this.form.quizzes.find(q => q._id == qid)

      return quiz
    },
    getChoiceById: function(qid, choice) {
      const quiz = this.getQuizById(qid)

      if (quiz) {
        const option = quiz.options.find(o => o._id == choice)

        return option
      }
    },
    questionName: function(qid) {
      const data = this.getQuizById(qid)

      if (data) {
        return data.question
      }
    },
    choiceName: function(qid, choice) {
      const data = this.getChoiceById(qid, choice)

      if (data) {
        return data.value
      }
    },
    choiceClass: function(qid, cid) {
      const choice = this.getChoiceById(qid, cid)

      if (choice) {
        switch(choice.answer) {
          case true:
            return 'has-text-success'

          case false:
            return 'has-text-danger'
        }
      }
    },
  },
  computed: {
    addFilterValid: function() {
      const filters = this.filters

      const v_question = filters.question
      const v_answer = filters.answer || filters.answer == ''

      return v_question && v_answer
    },
    unSelectedQuizzes: function() {
      return this.form.quizzes.filter(q => {
        const selected = this.selectedData.quizzes.find(sQ => sQ.quiz == q._id)

        return selected ? false : true
      })
    }
  },
  watch: {
    selectedData: {
      immediate: true,
      deep: true,
      handler: function(newValue) {
        this.getAnalysis(newValue)
      }
    }
  }
}
</script>

<style scoped>
</style>