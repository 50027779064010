<template>
  <div class="page-forms-analysis">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div v-if="loading == false" class="section">
          <div class="buttons">
            <b-button tag="router-link"
              :to="'/forms'"
              type="is-primary"
              icon-left="chevron-left">
              Back to Overview
            </b-button>
          </div>

          <h1 class="title">Form analysis for "<em>{{ form.name }}</em>"</h1>
          <hr>

          <h3 class="subtitle"><strong>Id: </strong><em>{{ form._id }}</em></h3>

          <b-tabs
            v-if="!$isEmpty(form)"
            v-model="analysisFormTab"
            position="is-right"
            type="is-toggle">

            <b-tab-item label="Complete Analysis">
              <FormCompleteAnalysis :form="form" />
            </b-tab-item>

            <b-tab-item label="Question filters">
              <FormAnalysis :form="form" />
            </b-tab-item>
          </b-tabs>
        </div>

        <b-loading :is-full-page="true" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import FormAnalysis from '@/components/FormAnalysis.vue'
import FormCompleteAnalysis from '@/components/FormCompleteAnalysis.vue'

export default {
  name: 'FormsAnalysis',
  components: {
    Sidebar,
    FormAnalysis,
    FormCompleteAnalysis,
  },
  data: function() {
    return {
      connection_error: false,
      loading: false,

      form: {},

      analysisFormTab: 0,

      lastRequest: {},
    };
  },
  methods: {
    fetch: function(requestOptions={}) {
      const thisCompo = this;

      this.loading = true;
      this.connection_error = false;
      this.lastRequest = requestOptions

      this.$api.formsList(requestOptions).then((response) => {
        if (response.data.list.length) {
          thisCompo.form = response.data.list[0]
        }

        thisCompo.loading = false
      }).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.loading = false;
        thisCompo.connection_error = true;
      });
    },
  },
  mounted: function() {
    this.fetch({
      id: this.$route.params.id,
      populate: ['quizzes']
    })
  }
}
</script>
